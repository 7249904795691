import React from "react"

import { Cover } from "components/anti/cover/cover"
import { Breadcrumb } from "components/anti/breadcrumb/breadcrumb-duti"

export const GlobalCover = ({ data, theme, breadcrumb, children, location }) => {

    return (
        <Cover
            variant="basic"
            theme={theme}
            breadcrumb={<Breadcrumb path={breadcrumb} location={location} />}
            img={data?.image?.sourceUrl}
            imgHeight="h-500px"
            title={data?.title}
            titleClassName="pt-sm-down-3 w-sm-down-250px align-cover-title"
            className="global-cover"
        >
            {children}
        </Cover>
    )
}