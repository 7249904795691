import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SEO from "components/seo"
import Layout from "components/layout"
import { GlobalCover } from "components/global/cover/cover"
import { GroupOrganizationalStructureImage } from "components/pages/investor/group-organizational-structure/image"

const GroupOrganizationalStructure = ({ path, pageContext, location }) => {
  const data = useStaticQuery(GET_INVESTOR_DETAILS)

  const lang = pageContext.langKey

  const pageData = lang === "en"
    ? data.wordPress.page.groupOrgStructurePage
    : data.wordPress.page.translation.groupOrgStructurePage
    || data.wordPress.page.groupOrgStructurePage

  const { seo, backgroundColor } = lang === "en"
    ? data.wordPress.page
    : data.wordPress.page.translation
    || data.wordPress.page

  const bgColor = backgroundColor?.backgroundColor?.color
  const SEODefaultImg = pageData.cover.image.sourceUrl

  return (
    <Layout path={path} theme={bgColor} lang={lang}>
      <SEO
        title={seo.title}
        desc={seo.metaDesc}
        fbImg={SEODefaultImg}
        fbTitle={seo.opengraphTitle}
        fbDesc={seo.opengraphDescription}
        url={location.href}
        twitterTitle={seo.twitterTitle}
        twitterDesc={seo.twitterDescription}
        twitterImg={SEODefaultImg}
      />
      <GlobalCover theme={bgColor} data={pageData.cover} breadcrumb={path} />
      <GroupOrganizationalStructureImage data={pageData.image} />
    </Layout>
  )
}

export default GroupOrganizationalStructure

const GET_INVESTOR_DETAILS = graphql`
query groupOrganizationSturcture {
    wordPress {
      page(id: "group-organization-structure", idType: URI) {
        backgroundColor {
          backgroundColor {
            color
          }
        }
        seo {
          title
          metaDesc
          twitterTitle
          twitterDescription
          opengraphTitle
          opengraphDescription
        }
        slug
        groupOrgStructurePage {
          image {
            sourceUrl
            altText
          }
          cover {
            title
            image {
              sourceUrl
              altText
            }
          }
        }
        translation(language: ID) {
          backgroundColor {
            backgroundColor {
              color
            }
          }
          seo {
            title
            metaDesc
            twitterTitle
            twitterDescription
            opengraphTitle
            opengraphDescription
          }
          groupOrgStructurePage {
            image {
              sourceUrl
              altText
            }
            cover {
              title
              image {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    }
  }
  
`