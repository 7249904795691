import React, { useLayoutEffect, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// import defaultSeoImg from "assets/img/seo-default.jpg"

import { replaceUnicode } from "components/anti/utils/utils"

import defaultImg from "../assets/ico/favicon-base.png"
function SEO({
  title = "Duta Pertiwi",
  desc = "Duta Pertiwi",
  url = "https://dutapertiwi.com",
  // url,
  // fbImg = "/static/seo-default-156108d25444752f9060d648ab2d4e0e.jpg",
  fbImg = defaultImg,
  fbDesc = "Duta Pertiwi",
  fbTitle = "Duta Pertiwi",
  // twitterImg = "/static/seo-default-156108d25444752f9060d648ab2d4e0e.jpg",
  twitterImg = defaultImg,
  twitterDesc = "Duta Pertiwi",
  twitterTitle = "Duta Pertiwi",
  schema,
  publishedDate,
  type = "website",
  origin,
  pathname,
  amp,
  ampSideBar,
}) {
  const { site } = useStaticQuery(DEFAULT_SEO)
  const [defaultSchema, setDefaultSchema] = useState({})

  useLayoutEffect(() => {
    setDefaultSchema(
      type === "article"
        ? {
            "@context": "https://schema.org",
            "@type": "Article",
            description: desc,
            image: fbImg,
            author: {
              "@type": "Organization",
              name: "Duta Pertiwi",
              url: window?.location?.origin || url,
            },
            publisher: {
              "@type": "Organization",
              name: "Duta Pertiwi",
              logo: {
                "@type": "ImageObject",
                url: `${window?.location?.origin}/static/logo-duta-pertiwi-dcaba23af5f0d511923385859ed32c30.svg`, // logo
              },
            },
            datePublished: publishedDate,
            sameAs: [
              "https://www.facebook.com/sinarmasland",
              "https://twitter.com/sinarmas_land",
              "https://www.instagram.com/sinarmas_land/",
              "https://www.youtube.com/channel/UCVxIPn2_4HYa18VclGzIqKg",
            ],
          }
        : {
            "@context": "https://schema.org",
            "@type": "Corporation",
            name: "Duta Pertiwi",
            url: url,
            logo: `${window?.location?.origin}/static/logo-duta-pertiwi-dcaba23af5f0d511923385859ed32c30.svg`, // logo
            sameAs: [
              "https://twitter.com/sinarmas_land",
              "https://www.facebook.com/sinarmasland/",
              "https://www.instagram.com/sinarmas_land/",
              "https://www.youtube.com/user/sinarmasland",
            ],
          }
    )
  }, [])

  const facebookPixelAllPagesDataLayer = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "fbPageview",
      page_url: window.location.href,
      page_title: title,
    })
  }

  useEffect(() => {
    facebookPixelAllPagesDataLayer()
  }, [])

  return (
    <Helmet title={replaceUnicode(title)} titleTemplate={`%s`} defer={false}>
      <meta
        name="title"
        content={`${replaceUnicode(title) || title || site.siteMetadata.title}`}
      />
      <meta property="description" content={desc} />
      {!amp && <link rel="canonical" href={url} />}


      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta
        property="og:title"
        content={`${replaceUnicode(fbTitle) ||
          fbTitle ||
          site.siteMetadata.title}`}
      />
      <meta
        property="og:image"
        content={fbImg || site.siteMetadata.metaImage}
      />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta
        property="og:image:alt"
        content={replaceUnicode(fbTitle) || fbTitle || site.siteMetadata.title}
      />
      <meta
        property="og:description"
        content={fbDesc || site.siteMetadata.description}
      />
      <meta property="fb:app_id" content="401011690922768" />

      <meta
        name="twitter:title"
        content={`${replaceUnicode(twitterTitle) || twitterTitle}`}
      />
      <meta
        name="twitter:image"
        content={twitterImg || site.siteMetadata.metaImage}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@sinarmas_land" />
      <meta name="twitter:site:id" content="@sinarmas_land" />
      <meta name="twitter:creator" content="@sinarmas_land" />
      <meta
        name="twitter:description"
        content={twitterDesc || site.siteMetadata.description}
      />
      <meta name="twitter:url" content={url} />

      <meta
        name="facebook-domain-verification"
        content="8kt76g2okvlnb77ua8hggpj896kyye"
      />

      <meta
        name="google-site-verification"
        content="8_Yt8Rsm4atP6FlrSF6EVfcz8_kjzVw7yjt-u2nrXOc"
      />
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index" />
      <meta name="googlebot" content="all" />

      {/*<script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>*/}

      {/* {ampSideBar ? (
        <meta name="viewport" content="width=device-width, initial-scale=1,  shrink-to-fit=no user-scalable=no, minimum-scale=1" />
      ) : (
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" />
      )} */}

        {!amp &&
            <script> window._izq = window._izq || []; window._izq.push(["init"]); </script>
        }

        {!amp &&
            <script src="https://cdn.izooto.com/scripts/62a7ca60d252d664c91ddd1623bcc109196a1258.js"></script>
        }

      <script type="application/ld+json">
        {JSON.stringify(schema || defaultSchema)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
  }),
  pathname: PropTypes.string,
}

export default SEO

const DEFAULT_SEO = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        metaImage
      }
    }
  }
`
