import React from "react"

import { Image } from "components/anti/image/image"

export const GroupOrganizationalStructureImage = ({ data }) => {
    return (
        <section className="py-main">
            <div className="container">
                <div className="d-flex justify-content-center">
                    <img src={data.sourceUrl} alt="orgStructure" className="img-fluid" />
                    {/* <Image src={data.sourceUrl} ratio="r-9-16" alt="orgStructure" className="w-100" /> */}
                </div>
            </div>
        </section>
    )
}